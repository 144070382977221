import {
  Button,
  Flex,
  Image,
  Link,
  Spacer,
  Text,
  VStack,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import { loginAdapter } from "../../api/adapter";
import { Input } from "../../components/atoms/Input";
import { PasswordInput } from "../../components/atoms/PasswordInput";
import { Title } from "../../components/atoms/Title";
import { useSettings, useTheme } from "../../context";

export function AdapterLogin() {
  const {  name, idp, logo, agreement, planSaleModule, bookstoreModule, primaryColor, primaryFontColor } = useTheme();
  const [isLoading, setIsLoading] = useState(false)
  const { isIOS } = useSettings();
  const toast = useToast();

  const navigate = useNavigate();

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  function redirect(token) {
    window.location.href = `/?token=${token}`;
  }

  const submitLogin = async () => {
    try {
        setIsLoading(true)
        const { data: logged } = await loginAdapter({
            username,
            password
        })
        if (logged.error) throw new Error(logged.error)
        setIsLoading(false)
        const slug = window.location.hostname.split(".")[0]
        localStorage.setItem(
            "auto-login",
            JSON.stringify({ token: logged.token, slug })
        );
        redirect(logged.token);
    } catch (error) {
        setIsLoading(false)
        if (error.response?.status === 403) {
            toast({
                title: "Erro",
                description:
                    "Usuário não está habilitado à usar o convênio Sercomtel. Entre em contato com o suporte.",
                status: "error",
                duration: 10000
            });
        } else {
            toast({
                title: "Erro",
                description: `Status: ${error.response.status} - ${error.response.data}` ,
                status: "error",
                duration: 10000
            });
        }
    }
  }
  useEffect(() => {
    let savedData = localStorage.getItem("auto-login");

    if (!savedData) return;

    try {
        savedData = JSON.parse(savedData);
        if (savedData.slug && savedData.token)
            redirect(savedData.token);
    } catch {
        localStorage.removeItem("auto-login");
    }
  }, []);

  /* if (!thirdPartyLogin) return <></>; */

  return (
      <VStack
          forcePrimaryColor={agreement?.primaryColor}
          forceSecondaryColor={agreement?.secondaryColor}
          marginTop={isIOS ? "-35px" : "0px"}
          paddingBottom={"100px"}
          justify="center"
          align="center"
          direction="column"
          margin="auto"
          width={{ base: "100%", lg: "50%" }}
      >
          <Flex justify="center" align="center" width="100%" height="100%" paddingBottom={{ base: "64px", lg: "0" }}>
              <Flex
                  display={{ base: "none", md: "flex" }}
                  flex="1"
                  height="100%"
                  backgroundColor="#FAFAFA"
                  justify="center"
                  align="center"
              >
                  <Image
                      w="300px"
                      objectFit="contain"
                      src={agreement?.logo || logo}
                  />
              </Flex>
              <Flex
                  direction="column"
                  spacing={10}
                  justify="center"
                  width="100%"
                  align="center"
                  minHeight="100%"
              >
                  <Flex justify="center" align="center" minH="250px" flex="1">
                      <Image
                          maxW="300px"
                          // w="30%"
                          objectFit="cover"
                          src={agreement?.logo || logo}
                      />
                  </Flex>
                  <VStack flex="1" width="80%">
                      <VStack
                          align="flex-start"
                          spacing={7}
                          width={{ base: "100%", lg: "60%" }}
                      >
                          <Title paddingBottom="10px">Entre utilizando seu nome de usuário e senha  {name}:</Title>
                          <Input
                              value={username}
                              onChange={setUsername}
                              placeholder="Nome de usuário"
                              type="text"
                          />
                          <VStack width="100%" spacing={2}>
                              <PasswordInput
                                  pr='4.5rem'
                                  value={password}
                                  onChange={setPassword}
                                  placeholder="Senha"
                              />
                              <Link
                                  fontSize="10px"
                                  alignSelf="end"
                                  textDecoration="underline !important"
                                  margin="0px"
                                  href="#"
                                  onClick={(e) => {
                                      e.preventDefault()
                                      toast({
                                          title: "Recuração de senha",
                                          description:
                                              `Para recuperar a senha da sua conta, deverá entrar em contato com o seu provedor ${name}.`,
                                          status: "error"
                                      });
                                  }}
                                  target="_blank"
                              >
                                  Esqueci minha senha
                              </Link>
                          </VStack>

                          <Spacer />
                          <Button
                              width="100%"
                              isLoading={isLoading}
                              onClick={submitLogin}
                          >
                              Entrar
                          </Button>
                          {planSaleModule && (
                              <>
                                  <Button backgroundColor={primaryColor} color={primaryFontColor} marginTop={-4} onClick={() => navigate("/signup")} width={"100%"}>
                                      Com Assinatura
                                  </Button>
                                  
                                  <Text paddingTop={"8px"} paddingBottom={"56px"} fontSize={12}>
                                      Cancelou o seu combo com a {name}? Sem problemas{" "}
                                      <LinkRouter
                                          to="/auth"
                                          style={{ textDecoration: "underline", color: primaryColor, fontSize: 13 }}
                                      >clique aqui</LinkRouter>
                                      {bookstoreModule && ', faça seu login e visualize todos os seus livros recebidos.'}
                                  </Text>
                              </>
                          )}
                      </VStack>
                  </VStack>
              </Flex>
          </Flex>
      </VStack>
  );
}
