import {
    Button,
    IconButton,
    Image,
    Spacer,
    Text,
    VStack,
    useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../axios";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context/index";
import { LoginWrapper } from "./LoginWrapper";

export function LoginOptionsPage() {
    const {
        primaryColor,
        promocodeModule,
        planSaleModule,
        bookstoreModule,
        integratedLogin,
        cpfModule,
        bookstoreType,
        metadata
    } = useTheme();
    const navigate = useNavigate();
    // const allowedSlugsToSSO = ["brisanet", "brisaclube", "brisanetbiblioteca", "brisanetrevistas"];
    const [slug, setSlug] = useState("");
    const toast = useToast();

    useEffect(() => {
        const hostname = window.location.hostname;
        setSlug(hostname.split(".")[0]);
    }, [window]);

    useEffect(() => {
        if (integratedLogin) {
            navigate("/login/parceiro");
        }
    }, [integratedLogin, navigate]);

    const handleSSOLogin = async (slug) => {
        try {
            const origin = window.location.origin;
            const response = await axiosInstance.post(
                "/integration/brisa/sso/",
                { origin: origin }
            );
            const { authorization_url } = response.data;
            window.open(authorization_url, "_blank", "width=600,height=700");
        } catch (error) {
            toast({
                title: "Erro",
                description:
                    "Não foi possível iniciar o login SSO. Tente novamente mais tarde.",
                status: "error"
            });
        }
    };

    const SSOIconButton = ({ iconSrc, onClick }) => (
        <IconButton
            onClick={onClick}
            icon={<Image src={iconSrc} boxSize="1.5em" />}
            variant="outline"
            aria-label="SSO Login"
        />
    );

    return (
        <LoginWrapper>
            <VStack
                spacing={7}
                width={{ base: "100%", lg: "80%" }}
                paddingBottom={"48px"}
            >
                <Title alignSelf="flex-start" paddingBottom="10px">
                    Como deseja continuar:
                </Title>
                {promocodeModule ? (
                    <Button
                        backgroundColor="primary"
                        color={"white"}
                        // onClick={() => setValidationType("promocode")}
                        onClick={() => navigate("/login/promocode")}
                        width="100%"
                    >
                        Com Código Promocional
                    </Button>
                ) : (
                    <></>
                )}
                {cpfModule && (
                    <Button
                        backgroundColor="primary"
                        color={"white"}
                        // onClick={() => setValidationType("cpf")}
                        onClick={() => navigate("/login/document")}
                        width="100%"
                    >
                        {metadata?.buttonCpfName || "Com CPF/CNPJ Vinculado"}
                    </Button>
                )}
                {planSaleModule && (
                    <Button
                        backgroundColor="primary"
                        color={"white"}
                        // onClick={() => {
                        //     setValidationType(null);
                        //     setStep(LoginSteps.SUBSCRIPTION_DETAIL);
                        // }}
                        onClick={() => navigate("/signup")}
                        width="100%"
                    >
                        Com Assinatura
                    </Button>
                )}
                {/* {allowedSlugsToSSO.includes(slug) &&
          (
            <Button
              backgroundColor="primary"
              onClick={handleSSOLogin}
              width="100%"
            >
              Com empresa
            </Button>
          )
        } */}
                <Spacer border={`1px solid ${primaryColor}`} />

                <Button
                    variant="outline"
                    // onClick={() => {
                    //     setValidationType("login");
                    //     setStep(LoginSteps.LOGIN);
                    // }}
                    onClick={() => navigate("/login/auth")}
                    width="100%"
                >
                    Já tenho conta
                </Button>
                {bookstoreModule && bookstoreType === "book" && (
                    <Text
                        paddingTop={"8px"}
                        paddingBottom={"56px"}
                        fontSize={12}
                    >
                        Mesmo cancelando o seu combo, você ainda pode acessar os
                        livros recebidos anteriormente. Basta fazer login acima.
                    </Text>
                )}
                {bookstoreModule && bookstoreType === "magazine" && (
                    <Text
                        paddingTop={"8px"}
                        paddingBottom={"56px"}
                        fontSize={12}
                    >
                        Mesmo cancelando o seu combo, você ainda pode acessar as
                        suas revistas recebidas anteriormente. Basta fazer login
                        acima.
                    </Text>
                )}
            </VStack>
        </LoginWrapper>
    );
}
