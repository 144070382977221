import { HStack, Select } from "@chakra-ui/react";
import React from "react";
import { useProfile } from "../../../context/user";
import { useTheme } from "../../../context";

export function PlanSelector({ plans, selectedPlan, setSelectedPlan }) {
    const { plans: userPlans = [] } = useProfile();
    const {primaryColor} = useTheme();

    return (
        <HStack
            marginBottom="30px"
            borderRadius="10px"
            justify="space-evenly"
            width="100%"
        >
            <Select
                borderColor={primaryColor}
                onChange={(e) => setSelectedPlan(parseInt(e.target.value))}
                backgroundColor={"white"}
                borderRadius="20px"
                value={selectedPlan}
                _focus={{ borderColor: primaryColor }}
                width="100%"
            >
                {plans
                    .sort((a, b) => a.level - b.level)
                    .map((p, index) => (
                        <option key={index} value={index}>
                            {p.name}
                        </option>
                    ))}
            </Select>
        </HStack>
    );
}
